<template>
  <div class="report">
    <div class="page-header">
      <h1>{{ $t('Report') }} > {{$t('Overview') }} </h1>
    </div>
    <div class="charts-cards">
      <div class="chart-inner">
        <h2>{{ $t('Total Sales') }}</h2>
        <TotalSales />
      </div>
      <div class="chart-inner">
        <h2>{{ $t('Total Shoppers') }}</h2>
        <TotalCustomers />
      </div>
      <div class="chart-inner">
        <h2>{{ $t('Total Orders') }}</h2>
        <TotalOrders />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import TotalSales from '@/components/dashboard/TotalSales.vue';
import TotalOrders from '@/components/dashboard/TotalOrders.vue';
import TotalCustomers from '@/components/dashboard/TotalCustomers.vue';

export default {
  name: 'Report',
  components: {
    TotalSales,
    TotalOrders,
    TotalCustomers,
  },
  computed: mapState({
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style scoped lang="scss">
.charts-cards{
  display: flex;
  flex-wrap: wrap;
}
.chart-inner{
  -webkit-box-flex: 0;
  -ms-flex: 0 0 30%;
  flex: 0 0 30%;
  margin-right: 10px;
  margin-top: 50px;
  background-color: white;
  padding: 20px;
}
</style>
